import React, { useState, useEffect } from 'react';
import Header from '../shared/Header';
import paz from '../assets/imgs/paz.png'

import linkedin from '../assets/icns/linkedin.svg'
import whatsapp from '../assets/icns/whatsapp.svg'

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 

  return (
  
    <div className='h-100 d-flex justify-content-center align-items-top'>
      <div className='row'>
      <div className='col-sm-2 d-none d-lg-block'></div>
      <div className='col-sm col-xs-12 '>
          <div className='row p-2'>
            <div className='col-8'>
              <h1 className='fw-600'>
                  I Think We Should Schedule Our First Date
                  </h1>
                      
            </div>

            <div className='col-12 mt-4'>
                <p className='fs-22 fw-400'>Reach out</p>
                  <a className='fs-24 text-dark fw-500 my-3' href="mailto:hello@fezzik.art">hello@fezzik.art&nbsp;↗</a>
                    <br></br>
                        <a className='fs-24 text-dark fw-500 my-3' href="https://api.whatsapp.com/send?phone=972528450488">Whatsapp&nbsp;↗</a>


              <p className='fs-22 mt-4 fw-400'>Socials</p>
                <a className='fs-20 text-dark fw-400 border-bottom-dark-1px py-1 me-3' href="https://www.instagram.com/fezzikart/" target='blank'>Instagram</a>
                <a className='fs-20 text-dark fw-400 border-bottom-dark-1px py-1 me-3' href="https://www.linkedin.com/company/fezzik-art/?viewAsMember=true" target='blank'>Linkedin</a>
                <a className='fs-20 text-dark fw-400 border-bottom-dark-1px py-1 me-3' href="/" target='blank'>Vimeo</a>
                <a className='fs-20 text-dark fw-400 border-bottom-dark-1px py-1' href="https://www.youtube.com/@fezzik_art" target='blank'>Youtube</a>
            </div>
        </div>
      </div>
      </div>
      </div>
  );
};

export default ContactPage;



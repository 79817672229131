import React, {useRef, useEffect } from 'react';

const MenuScreen = ({ isDarkBackground, setIsMenuOpen }) => {


    const closeMenu = () => {
      setIsMenuOpen(false);

        // setTimeout(() => {
        // }, 500); 
    };


  const redirectToContact = () => {
    window.location.href = '/contact';
    setIsMenuOpen(false);

  };

  const redirectToWorks = () => {
    window.location.href = '/';
    setIsMenuOpen(false);

  };

  const redirectToAbout = () => {
    window.location.href = '/about';
    setIsMenuOpen(false);

  };



  return (
    <div className="menu w-100 h-100" >
            <div className=''>
                <div
                onClick={closeMenu}
                    className=''>
                <p className='fs-16 fw-700 pointer '>Close</p>
            </div>
        </div>
           <div className="p-4">
              <h3 onClick={redirectToWorks} className='mb-3 fs-32 fw-600 pointer'>
                  Works
              </h3>
              <h3 onClick={redirectToAbout} className='mb-3 fs-32 fw-600 pointer'>
                  About
              </h3>
              <h3 onClick={redirectToContact} className='mb-3 fs-32 fw-600 pointer'>
                  Contact
              </h3>
            </div>
        </div>
      );
    }
  
  export default MenuScreen;
  
import React, { useState } from 'react';
import { useLocation,Link } from 'react-router-dom';

import logoDark from '../assets/logo.svg';
import Menu from './Menu';
import menu from '../assets/icns/menu.svg';


const Header = ({ isDarkBackground }) => {

  const logoSrc = isDarkBackground ? logoDark : logoDark ;
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
    

    const openMenu = () => {

        document.body.style.overflow = 'hidden'; 
    };

    const closeMenu = () => {

      document.body.style.overflow = 'auto'; 
    };





  return (


    <header className="d-flex w-100 justify-content-between align-items-start p-3 mx-auto">
        {isOpen && <Menu isDarkBackground={true} setIsMenuOpen={setIsOpen} />}

        <div className="logo-container">
        <Link to="/">
          <img src={logoDark} alt="Logo" className="me-3" style={{ width: "160px" }} />
          </Link>
        </div>
        <p className="m-0 d-none d-md-block fs-5">Paz Roth, <span className='fw-200'>Art director and animator</span></p>

        <div className="d-flex">
        <Link to="/contact">
          <button className="btn-clean-primary me-4 d-none d-md-inline fs-5">Get quote</button>
          </Link>
          {/* <button className="btn btn-outline-secondary me-3 d-none d-md-inline">Contact</button> */}
          <img onClick={() => { setIsOpen(!isOpen);}} src={menu} alt="menu" width="30" height="auto" />
        </div>
    </header>

      );
    }
  
  export default Header;
  
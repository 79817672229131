import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../shared/Header';

import otterize from '../assets/imgs/otterize/otterize-cube-logo-img.png'; 
import arrows from '../assets/imgs/arrows/cover-img.png'; 
import navina from '../assets/imgs/navina/navina00.png'; 
import navina2024 from '../assets/imgs/navina/navina-videos-cover-img.png'; 
import vayyar from '../assets/imgs/vayyar/cover-img.png'; 
import spark from '../assets/imgs/spark/cover-img.png'; 
import ennocure from '../assets/imgs/ennocure/cover-img.png'; 
import cottage from '../assets/imgs/cottage/cottage-cover.png'; 
import astrix from '../assets/imgs/astrix/cover-img.png'; 
import komodor from '../assets/imgs/komodor/cover-img.png'; 
import clariter from '../assets/imgs/clariter/clariter00.png'; 
import monet from '../assets/imgs/monet/cover-img.png'; 
import geoxITC from '../assets/imgs/geoxITC/cover-img.jpg'; 




const HomePage = ({}) => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []); 

  return (
    <div className=''>

          <div className='row px-4'>
            <div className='col-2'>
              </div>
            <div className='col-sm col-xs-12'>

        <div className='row '>
        <div className='col-md-6 col-sm-6 col-xs-12 p-2 pointer mb-4'>
            <Link to="/navina-risk">
                <div className='image-container ' >
                    <img className='img-fluid' src={navina2024} alt='otterize-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize'><span className='fw-200'>Navina:</span> AI-powered risk adjustment</h5>
                  </div>
                  </Link>
              </div>

          <div className='col-md col-sm-6 col-xs-12 p-2'>
                <div className='image-container ' >
                    <img className='img-fluid' src={monet} alt='otterize-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize'>Monet Fintech | <span className='fw-600'>Coming Soon</span>  </h5>
                  </div>
              </div>

            <div className='col-md-6 col-sm-6 col-xs-12 p-2 pointer mb-4'>
            <Link to="/geoxITC">
                <div className='image-container ' >
                    <img className='img-fluid' src={geoxITC} alt='otterize-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize'>Geox ITC 2024 </h5>
                  </div>
                  </Link>
              </div>

              <div className='col-md-3 col-sm-6 col-xs-12 p-2 pointer mb-4'>
            <Link to="/navina">
                <div className='image-container ' >
                    <img className='img-fluid' src={navina} alt='otterize-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize'>Navina Web Animations </h5>
                  </div>
                  </Link>
              </div>

              <div className='col-md-3 col-sm-6 col-xs-12 p-2 pointer mb-4'
      >            <Link to="/arrows">

                <div className='image-container ' >
                    <img className='img-fluid' src={arrows} alt='otterize-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                    <h5 className='fs-18 fw-400 capitalize'>Arrows Edu Interaction </h5>
                  </div>
                  </Link>

              </div>





              <div className='col-md-3 col-sm-6 col-xs-12 p-2 pointer'>
                <Link to="/clariter">

                <div className='image-container ' >
                    <img className='img-fluid' src={clariter} alt='otterize-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                    <h5 className='fs-18 fw-400 capitalize'>Clariter Explained </h5>
                  </div>
                  </Link>

              </div>


              <div className='col-md-3 col-sm-6 col-xs-12 p-2 pointer'
              >
                
                <Link to="/vayyar">
                <div className='image-container ' >
                    <img className='img-fluid' src={vayyar} alt='vayyar-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize '>Vayyar App Animations </h5>
                  </div>
                  </Link>
              </div>

              <div className='col-md- col-sm-6 col-xs-12 p-2 pointer'
              >
              <Link to="/otterize">

                <div className='image-container' >
                    <img className='img-fluid' src={otterize} alt='otterize-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize '>Otterize Explainer </h5>
                  </div>
                  </Link>
              </div>
              
              <div className='col-md-3 col-sm-6 col-xs-12 p-2 pointer'
             >
              <Link to="/astrix">
                <div className='image-container ' >
                    <img className='img-fluid' src={astrix} alt='astix-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize '>Astrix Aniamted Explainer </h5>
                  </div>
                  </Link>
              </div>
              {/* <div className='col-md-4 col-sm-6 col-xs-12 p-2 pointer'
              onClick={() => onPageChange('Astrix')}>
                <div className='image-container ' >
                    <img className='img-fluid' src={otterize} alt='otterize-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize '>Geox Explained </h5>
                  </div>
              </div> */}
              <div className='col-md-3 col-sm-6 col-xs-12 p-2 pointer'
              >
                <Link to="/spark">
                <div className='image-container ' >
                    <img className='img-fluid' src={spark} alt='spark-cover' />
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize '>Spark Explained </h5>
                  </div>
                  </Link>
              </div>
              <div className='col-md-3 col-sm-6 col-xs-12 p-2 pointer'
             >
              <Link to="/komodor">
                <div className='image-container ' >
                    <img className='img-fluid' src={komodor} alt='komodor-cover'/>
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize '>Komodor's Booths </h5>
                  </div>
                  </Link>
              </div>


              {/* <div className='col-md-3 col-sm-6 col-xs-12 p-2 pointer'
              >
                <Link to="/cyber-ball">
                <div className='image-container ' >
                    <img className='img-fluid' src={cyberBall} alt='cyberBall-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize '>Cyber Ball HRRI </h5>
                  </div>
                  </Link>
              </div>
              <div className='col-md-3 col-sm-6 col-xs-12 p-2 pointer'
              >
                <Link to="/humorous-gestures">
                <div className='image-container ' >
                    <img className='img-fluid' src={kipGesture} alt='kip-gestures-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize '>Humorous Gestures </h5>
                  </div>
                  </Link>
              </div>
              <div className='col-md-3 col-sm-6 col-xs-12 p-2 pointer'
              >
                <Link to="/kip-museum">
                <div className='image-container ' >
                    <img className='img-fluid' src={kipMuseum} alt='kip-museum-cover' width={1920} height={1080}/>
                    </div>
                  <div className='my-2 text-dark'>
                      <h5 className='fs-18 fw-400 capitalize '>Kip in the museum </h5>
                  </div>
                  </Link>
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

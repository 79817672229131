import React, { useState, useEffect, useRef } from 'react';


import navina01 from '../../assets/imgs/navina/navina-risk-video-1.png'; 
import navina02 from '../../assets/imgs/navina/navina-risk-video-2.png'; 
import navina03 from '../../assets/imgs/navina/navina-risk-video-3.png'; 
import navina04 from '../../assets/imgs/navina/navina-risk-video-4.png'; 
import navina05 from '../../assets/imgs/navina/navina-risk-video-5.png'; 


const NavinaRiskPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 






  return (
              
        <div className='row px-5 pb-5'>
          <div className='col-sm'></div>
          <div className='col-sm-9 col-12'>

                <div className='row d-flex align-items-top mb-4 py-5'>
                  <div className='col-md-6 col-12 text-start'>
                  <h1 className='fw-300 capitalize'>Navina Risk Adjustment</h1>
                  <p className='fw-200'>2024</p>

                  </div>
                  <div className='col-md col-12'>
                    <p className='fw-300'>
                    The AI powered value-based care workflows

                    </p>
                  <p className='fw-300 '>

                  We collaborated with our friends at Navina to create visual materials showcasing their latest product features for marketing purposes.                    </p>
                  </div>
                </div>

                    



              <video width="100%" height="auto" controls loop>
                    <source src="https://d4eb9hkrm2864.cloudfront.net/portfolio/navina_video01.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>


                    <div className='pt-4'>
                    <p className='fw-300'>
                    From refining a minimalist 2D style to crafting transitions that flow with purpose, every detail was designed to keep viewers engaged for the full two minutes. The result is a film that transforms massive content into a visually compelling and easy-to-digest explainer tool.
                    </p>
                    <p className='fw-300'>
                    This short film covers Navina's risk adjustment product and its key features, helping physicians master value-based care. The goal was to create a visual identity for the product that speaks to care providers while remaining fresh and dynamic. The animation needed to feel effortless—intuitive, seamless, and clear—just like the AI-powered platform itself."
                    </p>
                    <p className='fw-300'>
                    Seven people took part in the production of the video. We collaborated closely, making iterations along the way to refine the film until it was just perfect.                    </p>
                    </div>
   

            
              <div className='col-12 mt-5'>
                <img className='py-2'
                    src={navina01}
                    width='100%'
                  />

                  <img className='py-2'
                    src={navina02}
                    width='100%'
                  />

                  <img className='py-2'
                    src={navina03}
                    width='100%'
                  />

                  <img className='py-2'
                  src={navina04}
                  width='100%'
                  />

                  <img className='py-2'
                  src={navina05}
                  width='100%'
                  />


              </div>




              <div className='col-12 mt-5'>

              <div className='py-2'>
                    <h5 className='fs-18 fw-600'>Client: Navina</h5>
                  </div>


                  <div className='py-2'></div>

                  <div className='d-block'>
                    <h5 className=''>Credits:</h5>
                    <ul className='p-0'>
                    <li>Art Direction: Paz Roth</li>
                    <li>Script: Navina team, Paz Roth</li>
                    <li>Storyboard: Paz Roth, Helit Sayfan-Altman</li>
                    <li>UI design: Helit Sayfan-Altman</li>
                    <li>Animation: Paz Roth</li>
                    <li>Sound design & scoring: Tal Steinberg</li>
                    <li>Voiceover: Ted Pert</li>
                    </ul>

                  </div>

                  <div className='py-2'></div>


                  <div className='row'>
                    <div className='col'>
                      <h5 className='fs-18 fw-600'>Capabilities</h5>
                        <ul className='p-0'>
                          <li className='py-1'>Web Animation</li>
                          <li className='py-1'>Product Design</li>
                          <li className='py-1'>UX/UI Review</li>
                          <li className='py-1'>Design System</li>
                        </ul>
                    </div>
                    <div className='col'>
                      <h5 className='fs-18 fw-600'>Softwares</h5>
                        <ul className='p-0'>
                          <li className='py-1'>After Effect</li>
                          <li className='py-1'>Figma</li>
                          <li className='py-1'>Illustrator</li>
                          <li className='py-1'>Lottie</li>
                        </ul>
                    </div>
                    
                    <div className='col'>
                      <h5 className='fs-18 fw-600'>Cooperation</h5>
                        <ul className='p-0'>
                          <li className='py-1'>Project</li>

                        </ul>
                    </div>

                    <div className='col'>
                      <h5 className='fs-18 fw-600'>Industry</h5>
                          <li className='py-1'>SaaS Healthcare</li>
                          <h4 className='fs-18 fw-600 pt-2'>Duration</h4>
                          <li className='py-1'>2 Months, ongoing</li>

                    </div>

                  </div>


                    </div>
                </div>
              </div>


  );
};

export default NavinaRiskPage;
